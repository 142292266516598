import ActionConst from 'actions/const';

const errorsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionConst.RECEIVE_POSITION_ERROR:
    case ActionConst.RECEIVE_ERROR:
      if (action.data) {
        return [action.data];
      }
      return [`An error occurred for the action: ${action.actionType}`];
    default:
      return state;
  }
};

export default errorsReducer;
