import ActionConst from 'actions/const';

const investigationDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionConst.RECEIVE_INVESTIGATION_DETAILS:
      return { ...state, [action.data.id]: action.data };
    default:
      return state;
  }
};

export default investigationDetailsReducer;
