import { omit } from 'lodash/fp';
import * as Sentry from '@sentry/browser';
import ActionConst from 'actions/const';

import { AuthTokenKey, RefreshTokenKey } from '../utils/constants';

const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionConst.LOAD_SESSION:
      try {
        const accessStored = localStorage.getItem(AuthTokenKey);
        const refreshStored = localStorage.getItem(RefreshTokenKey);
        if (accessStored && refreshStored) {
          const data = JSON.parse(accessStored);

          if (data.account) {
            const { role, name, email } = data.account;
            Sentry.setUser({ role, name, email });
          }

          if (data) {
            return {
              ...state,
              ...data,
              refreshToken: refreshStored,
            };
          }
        }
      } catch (e) {
        console.log(e);
        localStorage.removeItem(AuthTokenKey);
        localStorage.removeItem(RefreshTokenKey);
        return omit(['token', 'refreshToken'], state);
      }
      break;
    case ActionConst.RECEIVE_AUTH_TOKEN:
      if (action.data?.accessToken.account) {
        const { role, name, email } = action.data.accessToken.account;
        Sentry.setUser({ role, name, email });
      }

      localStorage.setItem(
        AuthTokenKey,
        JSON.stringify(action.data.accessToken)
      );
      localStorage.setItem(RefreshTokenKey, action.data.refreshToken);
      return {
        ...state,
        ...action.data.accessToken,
        refreshToken: action.data.refreshToken,
      };
    case ActionConst.DELETE_AUTH_TOKEN: {
      localStorage.removeItem(AuthTokenKey);
      localStorage.removeItem(RefreshTokenKey);
      return omit(['token', 'account', 'refreshToken'], state);
    }
    default:
      break;
  }
  return state;
};

export default sessionReducer;
