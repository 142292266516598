import { createBrowserHistory as createHistory } from 'history';
import configureStore from './configureStore';

let history;
let store;

export const getStore = () => {
  if (!store) {
    store = configureStore({}, getHistory());
  }
  return store;
};

export const getHistory = () => {
  if (!history) {
    history = createHistory();
  }
  return history;
};
