const ActionConst = Object.freeze(
  [
    'REQUEST_SIGN_IN',
    'LOAD_SESSION',
    'RECEIVE_ADMINS',
    'UPDATE_ADMIN',
    'CREATE_ADMIN',
    'RECEIVE_ADMIN_ROLES',
    'RECEIVE_ALERT',
    'RECEIVE_ERROR',
    'RECEIVE_POSITION_ERROR',
    'RECEIVE_ACCOUNT',
    'RECEIVE_ACCOUNTS',
    'RESET_ACCOUNT',
    'RECEIVE_AUTH_TOKEN',
    'DELETE_AUTH_TOKEN',
    'REQUEST_QUICK_SEARCH',
    'QUICK_SEARCH',
    'RESET_QUICK_SEARCH',
    'RECEIVE_ACCOUNT',
    'RECEIVE_OWNER_DETAILS',
    'RECEIVE_OWNER_TAX_ID',
    'RECEIVE_ACCOUNT_NOTES',
    'CREATE_ACCOUNT_NOTE',
    'EDIT_ACCOUNT_NOTE',
    'DELETE_ACCOUNT_NOTE',
    'RECEIVE_ACCOUNT_EMAILS',
    'CREATE_ACCOUNT_EMAIL',
    'RECEIVE_MARGIN',
    'RECEIVE_ACCOUNT_DOCUMENTS',
    'RECEIVE_ACCOUNT_DOCUMENT_REQUESTS',
    'RECEIVE_SNAPS',
    'RECEIVE_TRANSFERS',
    'RECEIVE_RELATIONSHIP',
    'RECEIVE_WIREBANK',
    'APPROVE_TRANSFER',
    'REJECT_TRANSFER',
    'RECEIVE_POSITIONS',
    'REQUEST_POSITIONS',
    'RECEIVE_ALL_POSITIONS',
    'RECEIVE_ALL_POSITIONS_EXPANDED',
    'CLEAR_POSITIONS',
    'CLEAR_ADJUST_POSITION_ERROR',
    'ADJUST_POSITION_ERROR',
    'CLEAR_MARK_EXITED_POSITION_ERROR',
    'MARK_EXITED_POSITION_ERROR',
    'LOADING_ORDERS',
    'LOADING_ORDERS_FAILED',
    'RECEIVE_ORDERS',
    'LOADING_ORDER',
    'RECEIVE_ORDER',
    'FAILED_LOADING_ORDER',
    'RECEIVE_INVESTIGATION_DETAILS',
    'RECEIVE_INVESTIGATIONS',
    'ACCOUNT_REQUEST_CANCELED',
    'RECEIVE_DOCUMENT_REQUESTS',
    'ACCOUNT_APPROVED',
    'ACCOUNT_REVIEWED',
    'EDIT_ASSIGNED_ADMIN',
    'SEARCH_ACCOUNTS',
    'EDIT_ACCOUNT',
    'EDIT_OWNER_DETAILS',
    'RESUBMIT_ACCOUNT',
    'SET_FILTER_PARAMS',
    'GET_GENERATE_CSV',
    'CLOSE_ACCOUNT',
    'RECEIVE_UPDATED_DOCUMENT',
    'RECEIVE_BULK_APPROVE',
    'DO_NOTHING',
    'EDIT_PDT',
    'RECEIVE_TIMEANDTICKS',
    'SET_CORRESPONDENT_OPTIONS',
    'RECEIVE_ACTIVITIES',
    'RECEIVE_RISKY_ACCOUNTS',
    'APPEND_RISKY_ACCOUNTS',
    'RECEIVE_KYC_RESULTS',
    'RECEIVE_DAILY_BALANCES',
    'RECEIVE_ACCOUNT_STATUS_HISTORY',
    'RECEIVE_RISK_COUNTRIES',
  ].reduce((obj, v) => {
    obj[v] = Symbol(v); // eslint-disable-line no-param-reassign
    return obj;
  }, {})
);
export default ActionConst;
