import ActionConst from 'actions/const';

const blank = {
  snaps: null,
  account: null,
  accounts: null,
  riskyAccounts: { entries: [] },
  riskCountries: {},
  accountsMeta: null,
  documents: [],
  documentRequests: [],
  positions: null,
  positionMeta: null,
  loadingOrders: false,
  orders: null,
  order: null,
  orderLoading: false,
  transfers: null,
  relationships: [],
  wirebanks: [],
  ownerDetails: null,
  notes: [],
  emails: [],
  margin: {},
  admins: [],
  adminRoles: [],
  filterParams: null,
  positionError: null,
  isSearching: false,
  search: null,
  timeandticks: {},
  correspondentOptions: [],
};

const accountReducer = (state = blank, action) => {
  switch (action.type) {
    case ActionConst.RECEIVE_ACCOUNT:
      return {
        ...state,
        accounts: action.data.accounts,
        account: action.data.accounts[0],
        timeandticks: {},
      };
    case ActionConst.SET_CORRESPONDENT_OPTIONS:
      return {
        ...state,
        correspondentOptions: action.data.map((c) => c.correspondent),
      };
    case ActionConst.REQUEST_QUICK_SEARCH:
      return {
        ...state,
        isSearching: true,
        search: null,
      };
    case ActionConst.QUICK_SEARCH:
      return {
        ...state,
        isSearching: false,
        search: action.data,
      };
    case ActionConst.RESET_QUICK_SEARCH:
      return {
        ...state,
        search: null,
        isSearching: false,
      };
    case ActionConst.RECEIVE_ACCOUNTS:
      return {
        ...state,
        accounts: action.data.accounts,
        accountsMeta: action.data.meta,
      };
    case ActionConst.RECEIVE_RISKY_ACCOUNTS:
      return {
        ...state,
        riskyAccounts: action.data || { entries: [] },
      };
    case ActionConst.RECEIVE_RISK_COUNTRIES:
      return {
        ...state,
        riskCountries: action.data,
      };
    case ActionConst.APPEND_RISKY_ACCOUNTS:
      if (state.riskyAccounts) {
        return {
          ...state,
          riskyAccounts: {
            entries: state.riskyAccounts.entries.concat(action.data.entries),
            max_asof: action.data.max_asof,
          },
        };
      }
      return {
        ...state,
        riskyAccounts: action.data || { entries: [] },
      };
    case ActionConst.ACCOUNT_APPROVED: {
      const accounts = (() => {
        if (state.accounts) {
          return state.accounts.map((x) =>
            x.id === action.data
              ? {
                  ...x,
                  status: 'ACTIVE',
                }
              : x
          );
        }
        return null;
      })();

      const account = (() => {
        if (state.account) {
          if (action.data === state.account.id) {
            return {
              ...state.account,
              status: 'ACTIVE',
            };
          }
          return state.account;
        }
        return null;
      })();

      return {
        ...state,
        account,
        accounts,
      };
    }

    case ActionConst.RECEIVE_OWNER_DETAILS:
      return { ...state, ownerDetails: action.data };
    case ActionConst.EDIT_ACCOUNT: {
      const ownerDetails = {
        ...state.ownerDetails,
        ...action.data,
        tax_id: null,
      };
      return { ...state, ownerDetails };
    }
    case ActionConst.EDIT_PDT: {
      const account = state.account;
      account.pattern_day_trader = action.flag;
      return { ...state, account };
    }
    case ActionConst.EDIT_OWNER_DETAILS:
      return state;
    case ActionConst.RESUBMIT_ACCOUNT:
      return state;
    case ActionConst.RECEIVE_OWNER_TAX_ID:
      return {
        // add tax_id to the account details
        ...state,
        ownerDetails: {
          ...state.ownerDetails,
          tax_id: action.data.tax_id,
        },
      };
    case ActionConst.RECEIVE_SNAPS:
      return { ...state, snaps: action.data };
    case ActionConst.RECEIVE_TRANSFERS:
      return { ...state, transfers: action.data };
    case ActionConst.RECEIVE_RELATIONSHIP:
      return { ...state, relationships: [...state.relationships, action.data] };
    case ActionConst.RECEIVE_WIREBANK:
      return { ...state, wirebanks: [...state.wirebanks, action.data] };
    case ActionConst.RECEIVE_POSITIONS:
      return { ...state, positions: action.data };
    case ActionConst.REQUEST_POSITIONS:
      return { ...state, positionsLoading: true };
    case ActionConst.RECEIVE_POSITION_ERROR:
      return { ...state, positionsLoading: false, positions: null };
    case ActionConst.RECEIVE_ALL_POSITIONS:
      return {
        ...state,
        positions: action.data.positions,
        positionsLoading: false,
        positionMeta: action.data.meta,
      };
    case ActionConst.RECEIVE_ALL_POSITIONS_EXPANDED:
      return {
        ...state,
        expandedPositions: action.data.positions,
        positionsLoading: false,
        positionExpandedMeta: action.data.meta,
      };
    case ActionConst.CLEAR_POSITIONS:
      if (action.data === 'expanded') {
        return {
          ...state,
          expandedPositions: null,
          positionExpandedMeta: null,
        };
      }
      return { ...state, positions: null, positionMeta: null };
    case ActionConst.ADJUST_POSITION_ERROR:
      return { ...state, positionError: action.data };
    case ActionConst.CLEAR_ADJUST_POSITION_ERROR:
      return { ...state, positionError: null };
    case ActionConst.MARK_EXITED_POSITION_ERROR:
      return { ...state, positionError: action.data };
    case ActionConst.CLEAR_MARK_EXITED_POSITION_ERROR:
      return { ...state, positionError: null };
    case ActionConst.LOADING_ORDERS:
      return { ...state, loadingOrders: true };
    case ActionConst.LOADING_ORDERS_FAILED:
      return {
        ...state,
        loadingOrders: false,
        orders: { data: [], total_count: 0 },
      };
    case ActionConst.RECEIVE_ORDERS:
      return { ...state, orders: action.data, loadingOrders: false };
    case ActionConst.LOADING_ORDER:
      return { ...state, order: null, orderLoading: true };
    case ActionConst.FAILED_LOADING_ORDER:
      console.log('failed order loading');
      return { ...state, order: null, orderLoading: false };
    case ActionConst.RECEIVE_ORDER:
      return { ...state, order: action.data, orderLoading: false };
    case ActionConst.RECEIVE_INVESTIGATIONS:
      return { ...state, investigations: action.data };
    case ActionConst.RECEIVE_DOCUMENT_REQUESTS:
      return { ...state, document_requests: action.data };
    case ActionConst.RESET_ACCOUNT:
      return { ...state, account: null, timeandticks: {} };
    case ActionConst.RECEIVE_ACCOUNT_NOTES:
      return { ...state, notes: action.data };
    case ActionConst.CREATE_ACCOUNT_NOTE: {
      const notes = state.notes;
      notes.push(action.data);
      return { ...state, notes };
    }
    case ActionConst.EDIT_ACCOUNT_NOTE: {
      const notes = state.notes.map((note) =>
        note.id === action.data.id
          ? {
              ...action.data,
            }
          : note
      );
      return { ...state, notes };
    }
    case ActionConst.DELETE_ACCOUNT_NOTE: {
      const notes = state.notes.filter((note) => note.id !== action.noteID);
      return { ...state, notes };
    }
    case ActionConst.RECEIVE_ACCOUNT_EMAILS:
      return { ...state, emails: action.data };
    case ActionConst.CREATE_ACCOUNT_EMAIL: {
      const emails = state.emails;
      emails.push(action.data);
      return { ...state, emails };
    }
    case ActionConst.RECEIVE_MARGIN:
      return { ...state, margin: action.data };
    case ActionConst.RECEIVE_ADMINS:
      return { ...state, admins: action.data };
    case ActionConst.CREATE_ADMIN:
      return { ...state, admins: [action.data, ...state.admins] };
    case ActionConst.UPDATE_ADMIN: {
      const admins = state.admins.map((a) => {
        // replace admin with updated admin
        if (a.id === action.data.id) return action.data;
        return a;
      });
      return { ...state, admins };
    }
    case ActionConst.RECEIVE_ADMIN_ROLES:
      return { ...state, adminRoles: action.data.roles || [] };
    case ActionConst.EDIT_ASSIGNED_ADMIN:
      return state;
    case ActionConst.SEARCH_ACCOUNTS:
      return {
        ...state,
        accounts: action.data.accounts,
        accountsMeta: action.data.meta,
      };
    case ActionConst.SET_FILTER_PARAMS:
      return { ...state, filterParams: action.data };
    case ActionConst.GET_GENERATE_CSV:
      return { ...state, csvData: action.data };
    case ActionConst.RECEIVE_ACCOUNT_DOCUMENTS:
      return { ...state, documents: action.data };
    case ActionConst.RECEIVE_ACCOUNT_DOCUMENT_REQUESTS:
      return { ...state, documentRequests: action.data };
    case ActionConst.RECEIVE_TIMEANDTICKS:
      return {
        ...state,
        timeandticks: { ...state.timeandticks, ...action.data },
      };
    case ActionConst.RECEIVE_ACTIVITIES:
      return {
        ...state,
        activities: { ...action.data },
      };
    case ActionConst.RECEIVE_DAILY_BALANCES:
      return {
        ...state,
        dailyBalances: action.data,
      };
    case ActionConst.RECEIVE_KYC_RESULTS: {
      const newState = state;
      newState.account.status = action.data.status;
      newState.account.kyc_results = action.data.kyc_results;
      return {
        ...newState,
      };
    }
    case ActionConst.RECEIVE_ACCOUNT_STATUS_HISTORY: {
      return {
        ...state,
        statusHistory: action.data,
      };
    }
    default:
      return state;
  }
};

export default accountReducer;
