const config = require('../../config');
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const ADMIN_V2_URL = window.env?.ADMIN_V2_URL ?? '';
export const isPaper = (window.env?.admin_mode || config.admin_mode) == 'PAPER';
export const googleClientId =
  window.env?.google_client_id || config.google_client_id;
export const isLive = !isPaper;
export const AuthTokenKey = '@AUTH_TOKEN';
export const RefreshTokenKey = '@REFRESH_TOKEN';
