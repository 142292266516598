import querystring from 'querystring';
import { saveAs } from 'file-saver';
import ExtendableError from '../utils/extendableError';

export class InvalidCredentialException extends ExtendableError {
  constructor(code, message) {
    super(message || 'Invalid Credentials');
    this.code = code;
  }
}

class ApiException {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      this[key] = props[key];
    });
  }
}

const REST = (
  method,
  path,
  params = {},
  headers = {},
  session = null,
  saveAsFilename = null
) => {
  const endpoint = '/_admin/api/v1';
  const requestHeaders = Object.assign(
    {},
    {
      Accept: ['application/json', 'text/csv', 'application/pdf'],
      'Content-Type': 'application/json',
    },
    headers
  );
  let url = endpoint + path;
  const request = {
    method,
    headers: requestHeaders,
  };
  if (session && session.token) {
    requestHeaders.Authorization = `Bearer ${session.token}`;
  }

  if (/GET/i.test(method)) {
    const query = querystring.stringify(params);
    if (query) {
      url += `?${query.toString()}`;
    }
  } else {
    request.body = JSON.stringify(params);
  }
  console.debug('REST#REQUEST', url, request);

  return fetch(url, request)
    .catch((e) => {
      console.error(e);
      throw e;
    })
    .then((resp) => {
      if (resp.status == 204) {
        return null;
      }

      // for downloading CSV for account reports
      if (
        resp.headers &&
        resp.headers.get('content-type').split(';')[0] === 'text/csv'
      ) {
        return resp.text().then((data) => data);
      }

      // for zip
      if (resp.headers && resp.headers.get('content-disposition')) {
        let filename = '';
        const disposition = resp.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        return resp.blob().then((blob) => {
          saveAs(blob, filename);
        });
      }

      // if `saveAsFilename` was provided, save the response using `saveAs()`
      // it will automatically figure out the content type
      if (saveAsFilename) {
        return resp.blob().then((blob) => {
          saveAs(blob, saveAsFilename);
        });
      }

      // if (!resp.ok && resp.status !== 401 && resp.status !== 204) {
      //   throw new Error(`HTTP Error ${resp.status}: ${resp.statusText}`);
      // }

      return resp
        .json()
        .catch(() => {
          throw new Error(`HTTP Error ${resp.status}: ${resp.statusText}`);
        })
        .then((data) => {
          if (resp.status === 401 && !data.message.startsWith('admin role')) {
            throw new InvalidCredentialException(data.code, data.message);
          }
          console.debug('REST#RESPONSE', data);
          if (resp.ok || resp.status === 204) {
            return data;
          }
          throw new ApiException({
            path,
            method,
            params,
            status: resp.status,
            message: data.debug || data.message,
            detail: data.payload,
            data,
          });
        });
    });
};

REST.bindSession = (session) => (
  method,
  path,
  params,
  headers,
  saveAsFilename
) => REST(method, path, params, headers, session, saveAsFilename);

export default REST;
