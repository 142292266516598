import REST, { InvalidCredentialException } from './rest';
import ActionConst from '../actions/const';
import { getStore } from '../store';

const signIn = (params) => REST('POST', '/auth', params);

const refreshToken = (params) => REST('POST', '/auth/refresh', params);

const googleSignIn = (params) => REST('POST', '/auth_google', params);

const adminRoute = (handler) => (session, ...params) =>
  handler(REST.bindSession(session), session, ...params).catch((err) => {
    if (err instanceof InvalidCredentialException) {
      const { dispatch } = getStore();
      dispatch({
        type: ActionConst.DELETE_AUTH_TOKEN,
      });
    }
    throw err;
  });

const getAdmin = adminRoute((rest, session) =>
  rest('GET', `/accounts/${session.account.id}`)
);

const createAdmin = adminRoute((rest, session, body) =>
  rest('POST', `/${session.account.id}/administrators`, body)
);

const disableAdmin = adminRoute((rest, session, adminID) =>
  rest('DELETE', `/${session.account.id}/administrators/${adminID}`)
);

const updateAdmin = adminRoute((rest, session, adminID, body) =>
  rest('PATCH', `/${session.account.id}/administrators/${adminID}`, body)
);

const getAdmins = adminRoute((rest, session) =>
  rest('GET', `/${session.account.id}/administrators`)
);

const getAdminRoles = adminRoute((rest, session) =>
  rest('GET', `/${session.account.id}/admin-roles`)
);

const getCorrspondents = adminRoute((rest, session) =>
  rest('GET', `/${session.account.id}/correspondents`)
);

const getAccounts = adminRoute((rest, session, params) =>
  rest('GET', `/${session.account.id}/accounts`, params)
);

const getRiskyAccounts = adminRoute((rest, session, params) =>
  rest('GET', `/${session.account.id}/accounts/risk`, params)
);

const getCountryInfos = adminRoute((rest, session) =>
  rest('GET', `/${session.account.id}/country-infos`)
);

const search = adminRoute((rest, session, params) =>
  rest('GET', `/${session.account.id}/search`, params)
);

const getOwnerDetails = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/owner_details/${accountID}`)
);

const getOwnerTaxID = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/owner_details/${accountID}/taxid`)
);

const getAccountNotes = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/notes`)
);

const createAccountNote = adminRoute((rest, session, accountID, body) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/notes`, body)
);

const editAccountNote = adminRoute((rest, session, accountID, noteID, body) =>
  rest(
    'PATCH',
    `/${session.account.id}/accounts/${accountID}/notes/${noteID}`,
    body
  )
);

const deleteAccountNote = adminRoute((rest, session, accountID, noteID) =>
  rest('DELETE', `/${session.account.id}/accounts/${accountID}/notes/${noteID}`)
);

const getAccountEmails = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/emails`)
);

const createAccountEmail = adminRoute((rest, session, accountID, body) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/emails`, body)
);

const getMargin = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/margin-comparison`)
);

const listPositions = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/positions`)
);

const listAllPositions = adminRoute((rest, session, params) =>
  rest('GET', `/${session.account.id}/positions`, params)
);

const listAllPositionsConsolidated = adminRoute((rest, session, params) =>
  rest('GET', `/${session.account.id}/positions/consolidated`, params)
);

const listOrders = adminRoute((rest, session, accountID, params) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/orders`, params)
);

const listTransfers = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/transfers`)
);

const transferApproved = adminRoute(
  (rest, session, transferID, accountID, reason, clearOn) => {
    let body = { reason };
    if (clearOn) {
      body = { ...body, clear_on: clearOn };
    }
    return rest(
      'POST',
      `/${session.account.id}/accounts/${accountID}/transfers/${transferID}/approve`,
      body
    );
  }
);

const transferRejected = adminRoute(
  (rest, session, transferID, accountID, reason) =>
    rest(
      'POST',
      `/${session.account.id}/accounts/${accountID}/transfers/${transferID}/reject`,
      { reason }
    )
);

const getRelationship = adminRoute((rest, session, relationshipId) =>
  rest('GET', `/${session.account.id}/transfers/relationship/${relationshipId}`)
);

const getWireBank = adminRoute((rest, session, bankId) =>
  rest('GET', `/${session.account.id}/transfers/wirebank/${bankId}`)
);

const getRequestedDocuments = adminRoute((rest, session, investigationID) =>
  rest(
    'GET',
    `/${session.account.id}/investigations/${investigationID}/document_requests`
  )
);

const requestDocuments = adminRoute((rest, session, investigationID, params) =>
  rest(
    'POST',
    `/${session.account.id}/investigations/${investigationID}/document_requests`,
    params
  )
);

const listSnaps = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/snaps`)
);

const approveAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/approve`)
);

const bulkUpdate = adminRoute((rest, session, accountIDs, action) => {
  const path = action.replace(' ', '').toLowerCase();
  return rest('POST', `/${session.account.id}/accounts/${path}`, {
    account_ids: accountIDs,
  });
});

const reviewAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/review`)
);

const rejectAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/reject`)
);

const closeAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/close`)
);

const blockAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/block`)
);

const unblockAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/unblock`)
);

const blockTrading = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/block/trading`)
);

const unblockTrading = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/unblock/trading`)
);

const setPDT = adminRoute((rest, session, accountID, flag) =>
  rest('PUT', `/${session.account.id}/accounts/${accountID}/set-pdt/${flag}`)
);

const resubmitAccount = adminRoute((rest, session, accountID) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/resubmit`)
);

const cancelAccountRequest = adminRoute((rest, session) =>
  rest('DELETE', `/accounts/${session.account.id}/request`)
);

const editOwner = adminRoute((rest, session, accountID, body) =>
  rest('PATCH', `/${session.account.id}/owner_details/${accountID}/patch`, body)
);

const editBusinessOwner = adminRoute((rest, session, accountID, body) =>
  rest(
    'PATCH',
    `/${session.account.id}/owner_details/${accountID}/business-patch`,
    body
  )
);

const editAccount = adminRoute((rest, session, accountID, body) =>
  rest('PATCH', `/${session.account.id}/accounts/${accountID}/patch`, body)
);

const editAssignedAdmin = adminRoute((rest, session, accountID, body) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/assign`, body)
);

// only for testing on staging
const editStatus = adminRoute((rest, session, accountID, body) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/status`, body)
);

const getGenerateCSV = adminRoute((rest, session) =>
  rest('GET', `/${session.account.id}/generate`)
);

const generateAccountPDF = adminRoute((rest, session, accountID) =>
  rest(
    'GET',
    `/${session.account.id}/accounts/${accountID}/generate/pdf?type=account_dump`,
    {},
    {},
    `${accountID}.pdf`
  )
);

const regenerateAccountApplicationDocument = adminRoute(
  (rest, session, accountID) =>
    rest(
      'POST',
      `/${session.account.id}/accounts/${accountID}/regenerate/account_application`
    )
);

const liquidate = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/positions/liquidate`,
    body
  )
);

const liquidateAll = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/positions/liquidate/all`,
    body
  )
);

const bulkLiquidate = adminRoute((rest, session, body) =>
  rest('POST', `/${session.account.id}/positions/liquidate`, body)
);

const bulkMarksplit = adminRoute((rest, session, body) =>
  rest('POST', `/${session.account.id}/positions/marksplit`, body)
);

const marksplit = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/positions/marksplit`,
    body
  )
);

const adjustPosition = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/positions/adjust`,
    body
  )
);

const markExitedPosition = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/positions/mark-exited`,
    body
  )
);

const getOrder = adminRoute((rest, session, orderID) =>
  rest('GET', `/${session.account.id}/order/${orderID}`)
);

const cancelOrder = adminRoute((rest, session, accountID, body) =>
  rest('POST', `/${session.account.id}/accounts/${accountID}/cancel`, body)
);

const markOrderCanceled = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/mark-canceled`,
    body
  )
);

const markOrderRejected = adminRoute((rest, session, accountID, body) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/mark-rejected`,
    body
  )
);

const getAccountDocuments = adminRoute((rest, session, accountID) =>
  rest(
    'GET',
    `/${session.account.id}/documents/accounts/${accountID}?urls=true`
  )
);

const getAccountDocumentRequests = adminRoute((rest, session, accountID) =>
  rest('GET', `/${session.account.id}/documents/accounts/${accountID}/requests`)
);

const updateDocument = adminRoute((rest, session, documentID, body) =>
  rest('PATCH', `/${session.account.id}/documents/${documentID}`, body)
);

const getActivities = adminRoute((rest, session, accountID, params) =>
  rest('GET', `/${session.account.id}/accounts/${accountID}/activities`, params)
);

const getDailyBalances = adminRoute((rest, session, accountID, params) =>
  rest(
    'GET',
    `/${session.account.id}/accounts/${accountID}/daily-balances`,
    params
  )
);

const updateAccountConfigurations = adminRoute(
  (rest, session, accountID, body) =>
    rest(
      'PATCH',
      `/${session.account.id}/accounts/${accountID}/configurations`,
      body
    )
);

const getTimeAndTicks = adminRoute((rest, session, accountID, date) =>
  rest(
    'GET',
    `/${session.account.id}/accounts/${accountID}/margin/timeandticks/${date}`
  )
);

const createAccountDocumentRequest = adminRoute(
  (rest, session, accountID, body) => {
    const postBody = body;
    postBody.admin_id = session.account.id;
    postBody.account_id = accountID;
    return rest(
      'POST',
      `/${session.account.id}/documents/accounts/${accountID}/request`,
      postBody
    );
  }
);

const updateKYCResults = adminRoute((rest, session, accountID, body) => {
  const postBody = body;
  return rest(
    'PATCH',
    `/${session.account.id}/accounts/${accountID}/kyc`,
    postBody
  );
});

const getAccountStatusHistory = adminRoute((rest, session, accountID, params) =>
  rest(
    'GET',
    `/${session.account.id}/accounts/${accountID}/status-history`,
    params
  )
);

const requestResubmission = adminRoute((rest, session, accountID, params) =>
  rest(
    'POST',
    `/${session.account.id}/accounts/${accountID}/resubmission-request`,
    params
  )
);

export default {
  signIn,
  googleSignIn,
  refreshToken,
  getAdmin,
  createAdmin,
  disableAdmin,
  updateAdmin,
  getAdmins,
  listSnaps,
  getAccounts,
  getRiskyAccounts,
  search,
  getGenerateCSV,
  generateAccountPDF,
  blockAccount,
  blockTrading,
  getOwnerTaxID,
  listPositions,
  listAllPositions,
  listAllPositionsConsolidated,
  listOrders,
  listTransfers,
  transferApproved,
  transferRejected,
  rejectAccount,
  getRelationship,
  getWireBank,
  closeAccount,
  approveAccount,
  reviewAccount,
  bulkUpdate,
  unblockAccount,
  unblockTrading,
  editAccount,
  regenerateAccountApplicationDocument,
  editOwner,
  editBusinessOwner,
  resubmitAccount,
  requestDocuments,
  getOwnerDetails,
  getAccountNotes,
  createAccountNote,
  editAccountNote,
  deleteAccountNote,
  getAccountEmails,
  createAccountEmail,
  getMargin,
  cancelAccountRequest,
  getRequestedDocuments,
  editAssignedAdmin,
  editStatus,
  liquidate,
  liquidateAll,
  bulkLiquidate,
  bulkMarksplit,
  marksplit,
  adjustPosition,
  markExitedPosition,
  getOrder,
  cancelOrder,
  markOrderCanceled,
  markOrderRejected,
  getAccountDocuments,
  getAccountDocumentRequests,
  createAccountDocumentRequest,
  updateDocument,
  getActivities,
  updateAccountConfigurations,
  setPDT,
  getTimeAndTicks,
  getCorrspondents,
  updateKYCResults,
  getAdminRoles,
  getDailyBalances,
  getAccountStatusHistory,
  requestResubmission,
  getCountryInfos,
};
