import ActionConst from 'actions/const';

const blank = [];

const alertsReducer = (state = blank, action) => {
  switch (action.type) {
    case ActionConst.RECEIVE_ALERT:
      return [action.data];
    default:
      return state;
  }
};

export default alertsReducer;
