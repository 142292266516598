/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// import languageProviderReducer from 'containers/LanguageProvider/reducer';
import sessionReducer from 'reducers/session';
import accountReducer from 'reducers/account';
import alertsReducer from 'reducers/alerts';
import errorsReducer from 'reducers/errors';
import investigationDetailsReducer from 'reducers/investigationDetails';

/**
 * Creates the main reducer with the dynamically injected ones
 */
const createReducer = (history, injectedReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    // language: languageProviderReducer,
    session: sessionReducer,
    account: accountReducer,
    alerts: alertsReducer,
    errors: errorsReducer,
    investigationDetails: investigationDetailsReducer,
    ...injectedReducers,
  });

export default createReducer;
