/* eslint-disable */

const isNil = (x) => (x === null) || (typeof x === 'undefined')

const defaultConfig = {
  apiProxy: isNil(process.env.ADMIN_SERVICE_HOST) ?
    'http://localhost:6001/admin' : `http://${process.env.ADMIN_SERVICE_HOST}`,
  googleClientId: '541988098802-1u4f5glml39lkg4n1qm80vcg95iedaem.apps.googleusercontent.com'
};

function merge(o1, o2) {
  Object.keys(o2).forEach((key) => {
    if (typeof o1[key] === 'object' && typeof o2[key] === 'object') {
      merge(o1[key], o2[key]);
    } else {
      o1[key] = o2[key];
    }
  });
}

try {
  const local = require('./config.local.js');
  if (local) {
    merge(defaultConfig, local);
  }
} catch (e) {}


module.exports = defaultConfig;
