import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const init = () =>
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    environment: process.env.REACT_APP_SENTRY_ENV || 'production',
    release: process.env.REACT_APP_VERSION || 'unknown',
    integrations: [new Integrations.BrowserTracing()],
  });

export default init;
